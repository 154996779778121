import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap'
import HeaderOne from "../components/header/header"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import ReactMarkdown from "react-markdown"
import $ from "jquery";
import SEO from "../components/seo"

import StaticBanner from "../components/Static/StaticBanner/area-guide-static-banner"
import MGFav from '../components/Static/MgFavourites/MgFavouritesList'


const LocalLifeAreaCategoryLanding = (props) => {

    //const data = props.data.strapiAreaGuides
    const cuisines = props.data.allStrapiRestaurantCuisines.edges
    const category = props.data.strapiLocalLifeCategories
	

    var category_name = category.Alias
  	const [ data, setData ] = useState(props.data.strapiAreaGuides);

  	const [ eat_in, setEatIn ] = useState('');
  	const [ takeaways, setTakeaways ] = useState('');
  	const [ loadtext, setLoadText ] = useState('Loading');
  	const [ cuisine, setCuisine ] = useState('');
  	const [ filter_res, setFilterRes ] = useState('');

	var filter_resturant = [] , filter_resturant_cuisine = [];

	var things = [];
	for(var i=0; i < data.Local_life_things_to_dos.length; i++){
		if(data.Local_life_things_to_dos[i].Category === category.strapiId) {
		things.push(data.Local_life_things_to_dos[i])
		}
	}


	function filterTeam(event,data) {
 		const eat = $(".eat:checked").val()
 		const takeaways = $(".takeaways:checked").val()
 		const cusines = $("#cuisines").val()
 		setEatIn(eat);
 		setTakeaways(takeaways);
 		setCuisine(cusines);
	}


	useEffect(() => {

		if(eat_in === "eat-in" || takeaways === "takeaways"){
		filter_resturant = [];

			things.map((data,index) => {
				if((eat_in === "eat-in" && data.EatIn === true) && (takeaways === undefined)) {

			        filter_resturant.push(data);

			    } else if((takeaways === "takeaways" && data.Takeaways === true) && (eat_in === undefined)) {

			        filter_resturant.push(data);

			    }
			    else if((eat_in === "eat-in" && takeaways === "takeaways") && (data.EatIn === true && data.Takeaways === true)) {

			        filter_resturant.push(data);

			    }
			});

			if(cuisine !== "all") {
			filter_resturant_cuisine = [];
			filter_resturant.map((data,index) => {
				if(data.Cuisine == cuisine) {
					filter_resturant_cuisine.push(data);
				}
			});
			if(filter_resturant_cuisine.length == 0) {
				setFilterRes('');
			}
				filter_resturant_cuisine && filter_resturant_cuisine.length > 0 && setFilterRes(filter_resturant_cuisine);
			}

			else {
				filter_resturant && filter_resturant.length > 0 && setFilterRes(filter_resturant);
			}

		} else {
			if(cuisine === "all" || cuisine === '') {
			setFilterRes(things);
			}
			else {

			things.map((data,index) => {
			if(data.Cuisine == cuisine) {
					filter_resturant_cuisine.push(data);
				}
			});

			if(filter_resturant_cuisine.length == 0) {
				setFilterRes('');
			}
			filter_resturant_cuisine && filter_resturant_cuisine.length > 0 && setFilterRes(filter_resturant_cuisine);
			}
		}
    if(filter_res.length == 0) {
      setLoadText("Sorry, Not available for your selection")
    }


	},[eat_in,takeaways,cuisine])


    let intro_content = '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis lipsum dolor.</p>'
    let banner = 'https://ggfx-martyngerrard.s3.eu-west-2.amazonaws.com/i.prod/i_Stock_1164723619_2_3_aeb4ee159c.png'
    let banner_text = '<h1>'+category.Name+' in '+data.Title+'</h1>'
    let filter = "hide"
    let prop = "no-image"

    if(category_name == 'restaurant-and-bars') {
    	intro_content = data.Area_Local_Loving_Life?.Restaurants_and_Bars_Intro
    	banner = data.Restaurants_and_Bar_Banner_Image
    	filter = "show"
    	prop = "image"
    }
    else if(category_name == 'shops') {
    	intro_content = data.Area_Local_Loving_Life?.Shops_Intro
    	banner = data.Shops_Banner_Image
    	filter = "hide"
    	prop = "image"

    }
    else if(category_name == 'events') {
    	intro_content = data.Area_Local_Loving_Life?.Events_Intro
    	banner = data.Events_Banner_Image
    	prop = "image"
    }
    else if(category_name == 'clubs-and-societies') {
    	intro_content = data.Area_Local_Loving_Life?.Clubs_and_Societies_Intro
    	banner = data.Clubs_and_Societies_Banner_Image
    	prop = "image"
    }


	var meta_desc = "Craving a tasty meal out in "+data.Title+"? Discover our local agents’ best recommendations for kicking your cravings to the curbside."
	return (
		<div className="local-life-area-landing-page restaurant-landing">
	        <HeaderOne />
            <SEO location={props.location} title={data ? data.Meta_Title : ''} description={meta_desc} />

            {banner ? <StaticBanner areaguideData={data} Title={banner_text} CTA_1_URL="test" CTA_2_URL="#" category={category.Name} Banner_Image={banner} tag="local-life" main_url="main" prop={prop} /> :
			<StaticBanner Title={banner_text} CTA_1_URL="test" CTA_2_URL="#" category={category.Name} Banner_Image={'https://ggfx-martyngerrard.s3.eu-west-2.amazonaws.com/i.prod/i_Stock_1164723619_2_3_aeb4ee159c.png'} tag="local-life" main_url="main" prop={'no-image'} /> }

            <Container className="intro">
            	<Row className="content">
                	<Col xs={12} sm={12} lg={8}>
                		<div className="content-one">
                			<ReactMarkdown source={intro_content} allowDangerousHtml />
                		</div>
                	</Col>
                	<Col xs={12} sm={12} lg={4} className="east-schedule">
                		{filter == 'show' ?
                		<div className="filter">
                			<div className="cuisines">
                				<select name="cuisines" id="cuisines" className="cuisines" onChange={filterTeam}>
                					<option value="all">All Cuisines</option>
				                    {cuisines.map(({node}, i) => {
				                      return (
                						<option value={node.strapiId}>{node.Name}</option>
                					  )
                					})}
                				</select>
                			</div>
                			<div className="checkbox">
                				<Row>
                					<Col xs={6} className="east-checkbox">
                						<p><input type="checkbox" value="eat-in" className="eat" id="eat" name="Eat in" onClick={filterTeam} data-filter="eat_in"/><label for="eat">Eat in</label></p>
                					</Col>
                					<Col xs={6} className="east-checkbox">
                						<p><input type="checkbox" value="takeaways" className="takeaways" id="takeaways" onClick={filterTeam} data-filter="takeaways"/><label for="takeaways">Takeaways</label></p>
                					</Col>
                				</Row>
                			</div>
                		</div> : '' }
                	</Col>
            	</Row>
            </Container>
            {filter_res.length == 0 ?
            <div className="no-result">
            	<p>{loadtext}</p>
            </div> :
            <MGFav data={filter_res} url={category.URL} cat={category.strapiId}/> 
            }

	        <CanHelp />

	        <Footer Select_Popular_Search="Static_Contact" popularSearch="static"/>
		</div>
	)
}

export default LocalLifeAreaCategoryLanding


export const pageQuery = graphql`query LocalLifeAreaCategoryLandingQuery($slug: String!, $id: String!) {
  strapiAreaGuides(strapiId: {eq: $slug}) {
    Title
	URL
    id
    Area_Local_Loving_Life {
      Meta_Description
      Meta_Title
      Intro_Content
      Shops_Intro
      Banner_Content
      Banner_Image {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      Clubs_and_Societies_Intro
      Events_Intro
      Restaurants_and_Bars_Intro
    }
    Local_life_things_to_dos {
      URL
      Name
      EatIn
      Takeaways
      Cuisine
      Tile_Image_Label
      Tile_Image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: CONSTRAINED)
        }
      }
      Short_Description
      Address
      Category
      Featured
    }
    Shops_Banner_Image {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    Restaurants_and_Bar_Banner_Image {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    Events_Banner_Image {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    Clubs_and_Societies_Banner_Image {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
  strapiLocalLifeCategories(strapiId: {eq: $id}) {
    Banner_Content
    Intro_Content
    Meta_Description
    Meta_Title
    Name
    URL
    Alias
    id
    strapiId
  }
  allStrapiLocalLifeCategories {
    edges {
      node {
        Name
        URL
        Alias
        strapiId
        local_life_things_to_dos {
          area_guide
        }
      }
    }
  }
  allStrapiAreaGuides(
    filter: {Local_life_things_to_dos: {elemMatch: {Category: {eq: "6087c844d92e0f4521e8f2b8"}}}}
  ) {
    edges {
      node {
        Title
        URL
        strapiId
      }
    }
  }
  allStrapiRestaurantCuisines(filter: {Publish: {eq: true}}) {
    edges {
      node {
        Name
        Alias
        strapiId
      }
    }
  }
}
`
